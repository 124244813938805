caption {
  display: block;
  font-style: normal;
  font-weight: normal;
  color: #000000;
  font-size: 14px;
  text-align: center;
  line-height: 120%;

  @media only screen and (max-width: 1023px) {
    font-size: 12px;
  }
}

// .cardBlogDecs {
//   border-radius: 20px;
//   transition: 0.3s;
//   width: 100%;
//   cursor: pointer;
//   height: 100%;
// }

// .card-horizontal {
//   display: flex;
//   flex: 1 1 auto;
// }

.cardBlog:focus {
  box-shadow: 20px 20px 20px 0 rgba(0, 0, 0, 0.15);
  cursor: pointer;
}

.hideModal1 {
  @media only screen and (max-width: 568px) {
    width: 80%;
    top: 60%;
  }

  width: 75%;
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: none;
  outline: none;
  backdrop-filter: blur(24.7282px);
  border-radius: 20px;
  background: rgba(0, 0, 0, 0.3);
  border: 2px solid rgba(255, 255, 255, 0.2);
  padding: 24px;

  .title2 {
    //
    font-style: normal;
    font-weight: normal;
    color: #ffffff;
    text-align: center;
    font-size: 14px;

    @media only screen and (max-width: 1023px) {
      font-size: 12px;
      position: relative;
      bottom: 3px;
    }

    line-height: 135%;
    margin: 0;
  }

  .gtidSix {
    margin-top: 15px;
    display: grid;
    grid-template-columns: 75% auto;
    margin-left: 20px;
    margin-right: 20px;
  }
}

.hideModalNine {
  transition: visibility 0s, opacity 0.5s linear;
  backdrop-filter: blur(24.7282px);
  border-radius: 20px;
  background: rgba(0, 0, 0, 0.3);
  border: 2px solid rgba(255, 255, 255, 0.2);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: none;
  outline: none;

  @media only screen and (min-width: 568px) and (max-width: 1365px) {
    width: 75%;
  }

  @media only screen and (max-width: 567px) {
    width: 80%;
  }

  width: 60%;
  padding: 24px;

  // padding-right: 20px;
  // padding-top: 35px;
  // padding-bottom: 35px;
  .title1 {
    //
    font-style: normal;
    font-weight: 700;
    color: #ffffff;
    text-align: center;
    font-size: 16px;
    line-height: 135%;
    margin: 0;
  }

  .title {
    //
    font-style: normal;
    font-weight: normal;
    color: #ffffff;
    text-align: center;
    font-size: 14px;

    @media only screen and (max-width: 1023px) {
      font-size: 12px;
    }

    line-height: 135%;
    margin: 0;
  }

  .title2 {
    //
    font-style: normal;
    font-weight: normal;
    color: #ffffff;
    text-align: center;
    font-size: 14px;

    @media only screen and (max-width: 1023px) {
      font-size: 12px;
      position: relative;
      bottom: 3px;
    }

    line-height: 135%;
    margin: 0;
  }

  .gtidSix {
    margin-top: 15px;
    display: grid;
    grid-template-columns: 75% auto;
    margin-left: 20px;
    margin-right: 20px;
  }

  .gtidSix1 {
    margin-top: 15px;
    display: grid;
    grid-template-columns: auto auto auto;
    height: 100px;

    img {
      margin-top: 10px;
      width: 30px;
      height: 30px;
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0%);
    }
  }
}

.hideModalNine1 {
  transition: visibility 0s, opacity 0.5s linear;
  backdrop-filter: blur(24.7282px);
  border-radius: 20px;
  background: rgba(0, 0, 0, 0.3);
  border: 2px solid rgba(255, 255, 255, 0.2);
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: none;
  outline: none;

  @media only screen and (min-width: 568px) and (max-width: 1365px) {
    width: 75%;
  }

  @media only screen and (max-width: 567px) {
    width: 70%;
    top: 40%;
  }

  width: 60%;
  padding: 24px;

  // padding-right: 20px;
  // padding-top: 20px;
  // padding-bottom: 20px;
  .title1 {
    //
    font-style: normal;
    font-weight: 700;
    color: #ffffff;
    text-align: center;
    font-size: 16px;
    line-height: 135%;
    margin: 0;
  }

  .title {
    //
    font-style: normal;
    font-weight: normal;
    color: #ffffff;
    text-align: center;
    font-size: 14px;

    @media only screen and (max-width: 1023px) {
      font-size: 12px;
    }

    line-height: 135%;
    margin: 0;
  }

  .titleone2 {
    //
    font-style: normal;
    font-weight: normal;
    color: #ffffff;
    text-align: center;
    font-size: 14px;

    @media only screen and (max-width: 1023px) {
      font-size: 12px;
      position: relative;
      bottom: 2px;
    }

    line-height: 135%;
    margin: 0;
  }

  .title2 {
    //
    font-style: normal;
    font-weight: normal;
    color: #ffffff;
    text-align: center;
    font-size: 14px;

    @media only screen and (max-width: 1023px) {
      font-size: 12px;
    }

    line-height: 135%;
    margin: 0;
  }

  .gtidSix {
    margin-top: 15px;
    display: grid;
    grid-template-columns: 75% auto;
    margin-left: 20px;
    margin-right: 20px;
  }

  .gtidSix1 {
    margin-top: 15px;
    display: grid;
    grid-template-columns: auto auto auto;
    height: 100px;

    img {
      margin-top: 10px;
      width: 30px;
      height: 30px;
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0%);
    }
  }
}



.hideModalNine2 {
  transition: visibility 0s, opacity 0.5s linear;
  backdrop-filter: blur(24.7282px);
  border-radius: 20px;
  background: rgba(0, 0, 0, 0.3);
  border: 2px solid rgba(255, 255, 255, 0.2);
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: none;
  outline: none;

  @media only screen and (min-width: 568px) and (max-width: 1365px) {
    width: 75%;
  }

  @media only screen and (max-width: 567px) {
    width: 70%;
    top: 65%;
  }

  width: 60%;
  padding: 24px;

  // padding-right: 20px;
  // padding-top: 20px;
  // padding-bottom: 20px;
  .title1 {
    //
    font-style: normal;
    font-weight: 700;
    color: #ffffff;
    text-align: center;
    font-size: 16px;
    line-height: 135%;
    margin: 0;
  }

  .title {
    //
    font-style: normal;
    font-weight: normal;
    color: #ffffff;
    text-align: center;
    font-size: 14px;

    @media only screen and (max-width: 1023px) {
      font-size: 12px;
    }

    line-height: 135%;
    margin: 0;
  }

  .titleOne2 {
    //
    font-style: normal;
    font-weight: normal;
    color: #ffffff;
    text-align: center;
    font-size: 14px;

    @media only screen and (max-width: 1023px) {
      font-size: 12px;
      position: relative;
      bottom: 2px;
    }

    line-height: 135%;
    margin: 0;
  }

  .title2 {
    //
    font-style: normal;
    font-weight: normal;
    color: #ffffff;
    text-align: center;
    font-size: 14px;

    @media only screen and (max-width: 1023px) {
      font-size: 12px;
    }

    line-height: 135%;
    margin: 0;
  }

  .gtidSix {
    margin-top: 15px;
    display: grid;
    grid-template-columns: 75% auto;
    margin-left: 20px;
    margin-right: 20px;
  }

  .gtidSix1 {
    margin-top: 15px;
    display: grid;
    grid-template-columns: auto auto auto;
    height: 100px;

    img {
      margin-top: 10px;
      width: 30px;
      height: 30px;
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0%);
    }
  }
}



.fade-in-section.is-visible {
  opacity: 1;
  animation: fadeInStats 2s;
}

.fade-in-section.not-visible {
  opacity: 0;
  animation: fadeOutStats 0.5s;
}

@keyframes fadeInStats {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOutStats {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.hideModal {
  backdrop-filter: blur(24.7282px);
  border-radius: 20px;
  background: rgba(0, 0, 0, 0.3);
  border: 2px solid rgba(255, 255, 255, 0.2);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: none;
  outline: none;

  @media only screen and (min-width: 568px) and (max-width: 1365px) {
    width: 75%;
  }

  @media only screen and (max-width: 567px) {
    width: 80%;
  }

  width: 60%;
  padding: 24px;

  .title1 {
    //
    font-style: normal;
    font-weight: 700;
    color: #ffffff;
    text-align: center;
    font-size: 16px;
    line-height: 135%;
    margin: 0;
  }

  .title {
    //
    font-style: normal;
    font-weight: normal;
    color: #ffffff;
    text-align: center;
    font-size: 14px;

    @media only screen and (max-width: 1023px) {
      font-size: 12px;
    }

    line-height: 135%;
    margin: 0;
  }

  .title2 {
    //
    font-style: normal;
    font-weight: normal;
    color: #ffffff;
    text-align: center;
    font-size: 14px;

    @media only screen and (max-width: 1023px) {
      font-size: 12px;
    }

    line-height: 135%;
    margin: 0;
  }

  .titleOne2 {
    //
    font-style: normal;
    font-weight: normal;
    color: #ffffff;
    text-align: center;
    font-size: 14px;

    @media only screen and (max-width: 1023px) {
      font-size: 12px;
      position: relative;
      bottom: 2px;
    }

    line-height: 135%;
    margin: 0;
  }

  .gtidSix {
    margin-top: 15px;
    display: grid;
    grid-template-columns: 75% auto;
    margin-left: 20px;
    margin-right: 20px;
  }

  .gtidSix1 {
    margin-top: 15px;
    display: grid;
    grid-template-columns: auto auto auto;
    height: 100px;

    img {
      margin-top: 10px;
      width: 30px;
      height: 30px;
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0%);
    }
  }
}


.hideModalSleepMob {
  backdrop-filter: blur(24.7282px);
  border-radius: 20px;
  background: rgba(0, 0, 0, 0.3);
  border: 2px solid rgba(255, 255, 255, 0.2);
  position: absolute;
  bottom: 25%;
  width: 75%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: none;
  outline: none;

  .title1 {
    //
    font-style: normal;
    font-weight: 700;
    color: #ffffff;
    text-align: center;
    font-size: 16px;
    line-height: 135%;
    margin: 0;
  }

  .titleOne2 {
    //
    font-style: normal;
    font-weight: normal;
    color: #ffffff;
    text-align: center;
    font-size: 14px;

    @media only screen and (max-width: 1023px) {
      font-size: 12px;
      position: relative;
      bottom: 2px;
    }

    line-height: 135%;
    margin: 0;
  }

  .title2 {
    //
    font-style: normal;
    font-weight: normal;
    color: #ffffff;
    text-align: center;
    font-size: 14px;

    @media only screen and (max-width: 1023px) {
      font-size: 12px;
    }

    line-height: 135%;
    margin: 0;
  }

  .gtidSix {
    margin-top: 15px;
    display: grid;
    grid-template-columns: 70% auto;
    margin-left: 20px;
    margin-right: 20px;
  }
}

.hideModalSleep {

  backdrop-filter: blur(24.7282px);
  border-radius: 20px;
  background: rgba(0, 0, 0, 0.3);
  border: 2px solid rgba(255, 255, 255, 0.2);
  position: absolute;
  bottom: 40px;
  left: 40px;
  transform: translate(0, 0);
  border: none;
  outline: none;
  width: 300px;
  padding: 24px;

  .title1 {
    font-style: normal;
    font-weight: 700;
    color: #ffffff;
    text-align: center;
    font-size: 16px;
    line-height: 135%;
    margin: 0;
  }

  .title2 {
    font-style: normal;
    font-weight: normal;
    color: #ffffff;
    text-align: center;
    font-size: 14px;

    @media only screen and (max-width: 1023px) {
      font-size: 12px;
      position: relative;
      bottom: 3px;
    }

    line-height: 135%;
    margin: 0;

  }

  .gtidSix {
    margin-top: 15px;
    display: grid;
    grid-template-columns: 75% auto;
    margin-left: 20px;
    margin-right: 20px;
  }
}

@keyframes blink {

  from,
  to {
    opacity: 0;
  }

  20% {
    opacity: 1;
  }

  50% {
    opacity: 1;
  }
}

@-moz-keyframes blink {

  from,
  to {
    opacity: 0;
  }

  20% {
    opacity: 1;
  }

  50% {
    opacity: 1;
  }
}

@-webkit-keyframes blink {

  from,
  to {
    opacity: 0;
  }

  20% {
    opacity: 1;
  }

  50% {
    opacity: 1;
  }
}

@-ms-keyframes blink {

  from,
  to {
    opacity: 0;
  }

  20% {
    opacity: 1;
  }

  50% {
    opacity: 1;
  }
}

@-o-keyframes blink {

  from,
  to {
    opacity: 0;
  }

  20% {
    opacity: 1;
  }

  50% {
    opacity: 1;
  }
}


.imgPlay {
  width: 72px;
  height: 72px;
  transform: translate(-50%, -50%);
  position: absolute;
  z-index: 40;
  left: 50%;
  top: 50%;
  cursor: pointer;
}

.marginVideo {
  margin-left: 9vw;
  margin-right: 9vw;
  margin-top: 100px;

  @media only screen and (min-width: 568px) and (max-width: 1023px) {
    margin-top: 80px;
    margin-left: 2vw;
    margin-right: 2vw;
  }

  @media only screen and (max-width: 567px) {
    margin-top: 60px;
    margin-left: 2vw;
    margin-right: 2vw;
  }
}

.navlinkClass {
  //
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 144%;
  color: #000000;
  margin: 0;
  text-align: center;
  text-decoration: none;
}

.navlinkClassDesk {
  //
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 144%;
  color: #000000;
  margin: 0;
  text-align: left;
  text-decoration: none;
  cursor: pointer;
}

.imageDiv {
  width: 350px;
  height: 217px;
}

// .marginTopPad {
//   margin-left: 3.5vw;
//   margin-right: 3.5vw;
//   margin-top: 100px;
//   margin-bottom: 100px;
// }

.marginTopPaddingEbrochure {
  margin-top: 70px;
  // margin-bottom: 100px;
}

// .titlePT {
//   //
//   font-style: normal;
//   font-weight: 600;
//   font-size: 36px;
//   line-height: 98%;
//   color: #000000;
//   margin: 0;

//   @media only screen and (min-width: 768px) and (max-width: 1365px) {
//     font-size: 2.75em;
//   }

//   @media only screen and (max-width: 767px) {
//     font-size: 1.5em;
//   }
// }


#divID {

  p,
  li,
  span {
    //
    font-style: normal;
    font-weight: normal;
    color: #000000;
    text-align: left;
    font-size: 1em;
    line-height: 134%;
    margin: 0;
    margin-top: 40px;

    @media only screen and (max-width: 1024px) and (max-width: 1365px) {
      font-size: 14px;
    }

    @media only screen and (max-width: 1023px) {
      font-size: 12px;
      margin-top: 30px;
    }
  }
}

#faqDiv {
  margin-top: 40px;

  @media only screen and (max-width: 1023px) {
    margin-top: 30px;
  }
}

// .PTWidth {
//   margin: 0 auto;
//   max-width: 1440px;
//   overflow: hidden;
//   position: relative;
// }

// .PTWidthContact {
//   margin: 0 auto;
//   max-width: 780px;
//   overflow: hidden;
//   position: relative;
// }

.posTop {
  position: relative;
  bottom: 10px;
}

@keyframes fade {
  0% {
    opacity: 1;
  }

  12.5% {
    opacity: 1;
  }

  25% {
    opacity: 0;
  }

  37.5% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  67.5% {
    opacity: 0;
  }

  75% {
    opacity: 0;
  }

  87.5% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade2 {
  0% {
    opacity: 0;
  }

  12.5% {
    opacity: 0;
  }

  25% {
    opacity: 1;
  }

  37.5% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  67.5% {
    opacity: 0;
  }

  75% {
    opacity: 0;
  }

  87.5% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fade3 {
  0% {
    opacity: 0;
  }

  12.5% {
    opacity: 0;
  }

  25% {
    opacity: 0;
  }

  37.5% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  67.5% {
    opacity: 1;
  }

  75% {
    opacity: 0;
  }

  87.5% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fade4 {
  0% {
    opacity: 0;
  }

  12.5% {
    opacity: 0;
  }

  25% {
    opacity: 0;
  }

  37.5% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  67.5% {
    opacity: 0;
  }

  75% {
    opacity: 1;
  }

  87.5% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}



.secondRenderDesk {
  max-width: 100%;
  height: auto;
}

.image {
  display: block;
  max-width: 100%;
  height: auto;
}

.loading {
  filter: blur(10px);
  clip-path: inset(0);
}

.loaded {
  filter: blur(0px);
  transition: filter 0.5s linear;
}

.root {
  font-family: "Lato";
  position: relative;
  color: #fff;
  background: none;
  padding-bottom: 2rem;
}

@keyframes fadeInN {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.title {
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  position: relative;
  font-weight: 300;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
  margin: 0;
  padding: 0;
}

.popupDiv {
  position: absolute;
  z-index: 100;

  .labelClass {
    //
    font-style: normal;
    font-weight: normal;
    color: #8b8b8b;
    font-size: 14px;
    line-height: 145%;
    margin: 0;
  }

  .box {
    width: 40%;
    margin: 0 auto;
    background: rgba(255, 255, 255, 0.2);
    padding: 35px;
    border: 2px solid #fff;
    border-radius: 20px/50px;
    background-clip: padding-box;
    text-align: center;
  }

  .overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    transition: opacity 500ms;
    visibility: visible;
    opacity: 1;
  }

  .overlay:target {
    visibility: visible;
    opacity: 1;
  }

  .popup {
    margin: auto;
    padding: 40px;

    @media only screen and (max-width: 767px) {
      padding: 25px;
    }

    background: #fff;
    border-radius: 5px;
    width: 527px;
    position: relative;
    transition: all 1s ease-in-out;
    top: 10%;
  }

  p {
    margin: 0;
    padding-bottom: 25px;
  }

  .popup .close {
    position: absolute;
    top: 20px;
    right: 30px;
    transition: all 200ms;
    font-size: 30px;
    font-weight: bold;
    text-decoration: none;
    color: #333;
  }

  .popup .close:hover {
    color: #000000;
    cursor: pointer;
  }

  @media screen and (max-width: 700px) {
    .box {
      width: 70%;
    }

    .popup {
      width: 70%;
    }
  }
}

#popupDiv {
  .countryBorder button {
    border: 1px solid #000000;
    height: 43px;
    border-radius: 5px;
  }
}

.menu1 {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 144%;
  color: #000000;
  margin: 0;
  padding-bottom: 10px;
}



.menu2 {
  @media screen and (max-width: 567px) {
    font-size: 12px;
    padding-left: 5px;
    padding-right: 5px;
    text-align: center;
  }

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 144%;
  color: #000000;
  text-decoration: none;
  cursor: pointer;
}

#reviewId {

  position: relative;
  box-shadow: 0px 0px 41.5654px rgba(0, 0, 0, 0.05);
  padding: 30px 60px;
  border-radius: 20px;

  @media screen and (max-width: 567px) {
    padding: 30px;
  }

  .review-container {
    width: 100%;
  }

  .review-header {
    @media screen and (max-width: 567px) {
      display: block;
    }

    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  .user-info {
    display: flex;
    align-items: center;
  }

  .avatar {
    background-color: #F39526;
    color: white;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    margin-right: 10px;
  }

  .user-name {
    font-weight: 400;
    color: #F39526;
    font-size: 16px;
    line-height: 24px;
  }

  .post-date {
    font-weight: 400;
    color: #8B8B8B;
    font-size: 16px;
    line-height: 24px;
  }

  .rating .star {
    color: #F39526;
    font-size: 1.2em;
  }

  .review-body h2 {
    margin: 0 0 10px;
    font-weight: 700;
    color: #525252;
    font-size: 20px;
    line-height: 30px;

    @media screen and (max-width: 567px) {
      font-size: 16px;
      line-height: 24px;
    }
  }

  .review-body p {
    font-weight: 400;
    color: #525252;
    font-size: 16px;
    line-height: 24px;

    @media screen and (max-width: 567px) {
      font-size: 12px;
      line-height: 20px;
    }

    margin: 0;
  }

  // style.scss

  .slick-prev,
  .slick-next {
    display: none !important;
    // font-size: 0;
    // line-height: 0;
    // position: absolute;
    // top: 50%;
    // display: block;
    // width: 20px;
    // height: 20px;
    // padding: 0;
    // transform: translate(0, -50%);
    // cursor: pointer;
    // color: transparent;
    // border: none;
    // outline: none;
    // background: transparent;
  }

  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    opacity: 0.75;
    color: black;
  }

  .slick-prev {
    left: -40px;
  }

  .slick-next {
    right: -40px;
  }

  .slick-prev:before {
    content: "←";
  }

  .slick-next:before {
    content: "→";
  }

  // style.scss

  .slick-dots {
    display: flex !important;
    justify-content: flex-end;
    // margin-top: 20px;

    li {
      width: auto;
      margin: 0 5px;

      button {
        width: auto;
        height: auto;
        padding: 5px 10px;
        font-size: 14px;
        border: none;
        background: none;
        cursor: pointer;
        color: #000;
        transition: color 0.3s ease;

        &:before {
          display: none;
        }
      }

      &.slick-active button {
        color: #F39526;
        font-weight: bold;
      }
    }
  }


  .slick-dots {
    // display: none !important;
  }

  .custom-prev,
  .custom-next {
    cursor: pointer;
    font-size: 20px;
    user-select: none;
  }

}