

#container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 20px;
}

.itemS1New {
  flex: 0 0 calc(25% - 20px);
  @media only screen and (max-width: 567px) {
    flex: 0 0 calc(50% - 20px);

    margin: 10px;
  }
  margin: 20px;
  justify-content: center;
  position: relative;
  text-align: center;
  // background-color: aqua;
  .newDivssNew {
    // position: absolute;
    bottom: 0px;
    // padding-top: 20px;
  }
}
