// .content-header-wrapper {
//   margin: 0 auto;
//   max-width: 1440px;
//   overflow: hidden;
//   height: 100%;
// }

// .header-margin {
//   margin-left: 3.5vw;
//   margin-right: 3.5vw;
// }


// .liPara {
//   text-decoration: none;
//   cursor: pointer;
//   font-weight: bold;
//   font-style: normal;
//   font-weight: normal;
//   line-height: 120%;
//   color: #ffffff;
//   font-size: 14px;
// }

// .dhyana2Logo {
//   position: absolute;
//   width: 100px;
//   top: 30%;
// }

// .preOrderButtonH {
// font-style: normal;
// background: #ff8a00;
// border-radius: 46px;
// border: none;
// display: inline-block;
// font-weight: 600;
// transition: 0.5s;
// cursor: pointer;
// text-align: center;
// color: #ffffff;
// outline: none;
// padding: 10px 25px;
// font-size: 14px;
// width: 154px;
// height: 39px;

// @media only screen and (max-width: 567px) {
//   font-size: 12px;
//   height: 35px;
//   width: 130px;
//   padding: 6px 10px;
//   border-radius: 32px;
// }
// }

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
}




// .header-links p {
//   padding: 0 15px;
//   font-style: normal;
//   font-weight: normal;
//   line-height: 120%;
//   color: #ffffff;
//   font-size: 14px;
//   text-decoration: none;
//   cursor: pointer;

//   @media only screen and (min-width: 768px) and (max-width: 1023px) {
//     font-size: 12px;
//   }
// }

@media only screen and (min-width: 1024px) {
  // .ipadMobNav {
  //   display: none;
  // }

  // .desktopView-header {
  //   display: block;
  // }

  // .header-dropdown {
  //   display: inline-block;

  //   p {
  //     padding: 0 15px;
  //     font-style: normal;
  //     font-weight: normal;
  //     line-height: 120%;
  //     color: #ffffff;
  //     font-size: 14px;
  //     text-decoration: none;
  //     cursor: pointer;

  //     @media only screen and (min-width: 768px) and (max-width: 1023px) {
  //       font-size: 12px;
  //     }
  //   }
  // }

  // .header-dropdown-content {
  //   display: none;
  //   position: absolute;
  //   z-index: 1;
  //   width: 100%;
  //   background-color: transparent;
  //   padding-top: 13px;
  //   padding-bottom: 15px;

  //   div {
  //     background: rgba(0, 0, 0, 0.8);
  //     padding-bottom: 20px;
  //     padding-top: 15px;
  //   }
  // }

  // .header-dropdown-content a {
  //   color: black;
  //   padding: 12px 16px;
  //   text-decoration: none;
  //   display: block;

  //   font-style: normal;
  //   font-weight: normal;
  //   line-height: 120%;
  //   color: #ffffff;
  //   font-size: 14px;
  //   text-decoration: none;
  //   cursor: pointer;

  //   @media only screen and (min-width: 768px) and (max-width: 1023px) {
  //     font-size: 12px;
  //   }
  // }

  // .header-dropdown-content a:hover {
  //   background: rgba(0, 0, 0, 0.5);
  // }

  // .header-dropdown:hover .header-dropdown-content {
  //   display: block;
  // }


  // .mainDIv {
  //   position: fixed;
  //   width: 100%;
  //   top: 0;
  //   z-index: 999;
  //   background: rgba(0, 0, 0, 0.6);
  //   backdrop-filter: blur(27px);

  //   ul {
  //     background-color: pink;
  //     display: flex;
  //     list-style: none;

  //     li {
  //       cursor: pointer;
  //       text-decoration: none;
  //     }
  //   }
  // }

  .setsidenavOpenOverlayNone {
    display: none;
    overflow: hidden;
  }

  // .menu-toggle {
  //   display: none;
  //   overflow: hidden;
  // }
}

@media only screen and (max-width: 1023px) {
  // .ipadMobNav {
  //   display: block;
  // }

  // .desktopView-header {
  //   display: none;
  //   overflow: hidden;
  // }

  // .mainDIv {
  //   position: fixed;
  //   width: 100%;
  //   top: 0;
  //   z-index: 999;
  //   background: rgba(0, 0, 0, 0.6);
  //   backdrop-filter: blur(27px);
  //   height: 60px;
  // }

  .setsidenavOpenOverlayNone {
    display: block;
    overflow: hidden;
  }

  // .menu-toggle {
  //   position: absolute;
  //   right: 0px;
  //   top: 30px;
  //   cursor: pointer;
  // }

  .hamburger {
    content: "";
    display: block;
    background: #ffffff;
    height: 3px;
    width: 1.3em;
    border-radius: 2px;
    transition: all ease-in-out 500ms;
    position: relative;
    margin-right: 30px;

    @media only screen and (max-width: 567px) {
      margin-right: 20px;
    }
  }

  .hamburger::before,
  .hamburger::after {
    content: "";
    display: block;
    background: #ffffff;
    height: 3px;
    width: 1.3em;
    border-radius: 2px;
    transition: all ease-in-out 500ms;
    position: relative;
    margin-right: 30px;

    @media only screen and (max-width: 567px) {
      margin-right: 20px;
    }
  }

  .hamburgerOpen,
  .hamburgerOpen::before,
  .hamburgerOpen::after {
    transition: all ease-in-out 500ms;
    transform: rotate(45deg);
    content: "";
    display: block;
    background: #ffffff;
    height: 3px;
    width: 1.3em;
    border-radius: 2px;
    margin-right: 30px;

    @media only screen and (max-width: 567px) {
      margin-right: 20px;
    }
  }

  .hamburger::before {
    transform: translateY(-7px);
  }

  .hamburger::after {
    transform: translateY(4px);
  }

  .hamburgerOpen::before {
    opacity: 0;
  }

  .hamburgerOpen::after {
    transform: translateY(-3px) rotate(-90deg);
  }

  .setsidenavOpenOverlayNone {
    position: absolute;
    background: rgba(0, 0, 0, 0.1);
    height: 100%;
    width: 0%;
    z-index: 100;
    right: 0px;
    top: 60px;
    animation: menuAnima2 1s forwards;
    overflow: hidden;
  }

  .setsidenavOpenOverlay {
    position: absolute;
    background: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(25px);
    height: 800px;
    width: 100%;
    z-index: 999;
    right: 0px;
    top: 60px;
    animation: menuAnima 1s forwards;
  }

  @keyframes menuAnima {
    from {
      transform: translateX(100%);
    }

    to {
      transform: translateX(0%);
    }
  }
}