.banner {
  background-color: #eef8f1;
  border-radius: 5px;
  padding: 20px;
  margin-left: 3.5vw;
  margin-right: 3.5vw;
  position: relative;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;

  img {
    width: 24px;
    height: 24px;
  }

  @media only screen and (max-width: 567px) {
    img {
      width: 24px;
      height: 24px;
      position: relative;
      top: 8px;
    }

  }
}

.Bannercontent {
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  color: #08660D;
  font-size: 14px;
  text-decoration: none;
  cursor: pointer;
  position: relative;
  bottom: 5px;
  left: 10px;

  @media only screen and (min-width: 568px) {
    position: relative;
    top: 0px;
  }

  @media only screen and (max-width: 1023px) {
    text-align: center;
    font-size: 12px;
  }
}

.offer-button {
  display: inline-block;
  padding: 10px 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  color: #08660D;
  font-size: 12px;
  text-decoration: none;
  border-radius: 8px;
  font-weight: bold;
  position: absolute;
  right: 20px;
  border: 1px solid;
  bottom: 15%;
  background-color: transparent;
  cursor: pointer;

  @media only screen and (max-width: 1024px) {
    position: relative;
    top: 5px;
  }
}

.offer-button:hover {
  color: #ffffff;
  background-color: #08660D;

}