.contentWrapper {
  margin: 0 auto;
  max-width: 1440px;
  overflow: hidden;
  position: relative;
}

.marginDiv {
  margin-left: 3.5vw;
  margin-right: 3.5vw;

  @media only screen and (max-width: 567px) {
    margin-left: 17px;
    margin-right: 17px;
  }
}

.marginDivIndex {
  margin-left: 9vw;
  margin-right: 9vw;

  @media only screen and (max-width: 1023px) {
    margin-left: 2vw;
    margin-right: 2vw;
  }
}

.greetOrder {
  @media only screen and (max-width: 567px) {
    margin-top: 100px;
  }

  @media only screen and (min-width: 568px) {
    float: right;
    width: 480px;
  }
}

.BackgroundImg {
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
}

.marginTopBottomFirst1Order {
  margin-top: 12rem;

  @media only screen and (max-width: 567px) {
    margin-top: 13rem;
  }
}

.marginTopBottomFirstIndex {
  height: 100%;
}

.seconDivIndex {
  @media only screen and (min-width: 1024px) {
    margin-top: 100px;
    margin-bottom: 80px;
  }

  @media only screen and (min-width: 568px) and (max-width: 1023px) {
    margin-top: 80px;
    margin-bottom: 60px;
  }

  @media screen and (max-width: 567px) {
    margin-top: 60px;
    margin-bottom: 40px;
  }
}

.gridDivIndex {
  @media only screen and (min-width: 1024px) {
    margin-top: 40px;
  }

  @media only screen and (min-width: 568px) and (max-width: 1023px) {
    margin-top: 25px;
  }

  @media screen and (max-width: 567px) {
    margin-top: 15px;
  }
}

.gridDiv1Index {
  @media only screen and (min-width: 1024px) {
    margin-top: 0px;
  }

  @media only screen and (min-width: 568px) and (max-width: 1023px) {
    margin-top: 0px;
  }

  @media screen and (max-width: 567px) {
    margin-top: 15px;
  }
}

.sixteenDiv {
  @media only screen and (min-width: 1024px) {
    margin-top: 100px;
    margin-bottom: 85px;
  }

  @media only screen and (min-width: 568px) and (max-width: 1023px) {
    margin-top: 80px;
    margin-bottom: 65px;
  }

  @media screen and (max-width: 567px) {
    margin-top: 60px;
    margin-bottom: 45px;
  }
}





.PageContainer {
  display: grid;
  grid-template-columns: 50% 50%;
  border-radius: 20px;

  @media only screen and (max-width: 567px) {
    grid-template-columns: repeat(1, 1fr);
    background: #ffffff;
  }

  box-shadow: 0px 0px 41.5654px rgba(0, 0, 0, 0.02);
}

.Greet {
  position: relative;

  video {
    @media only screen and (max-width: 567px) {
      border-radius: 20px 20px 0px 0px;
    }

    @media only screen and (min-width: 568px) {
      border-radius: 20px 0px 0px 20px;
    }
  }
}

.Greet2ThirdComponent {
  background: #ffffff;
  height: 99.4%;
  position: relative;

  @media only screen and (max-width: 567px) {
    border-radius: 0px 0px 20px 20px;
  }

  @media only screen and (min-width: 568px) {
    border-radius: 0px 20px 20px 0px;
  }
}

.Greet1ThirdComponent {
  @media only screen and (min-width: 568px) {
    position: absolute;
    left: 62%;
    top: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
  }

  @media only screen and (max-width: 567px) {
    margin-top: 25px;
    margin-bottom: 40px;
    margin-left: 40px;
    margin-right: 40px;
  }
}



.Para1ThirdComponent {
  font-style: normal;
  font-weight: 700;
  font-size: 3.375em;
  line-height: 98%;
  color: #000000;
  margin: 0;

  @media only screen and (min-width: 1024px) and (max-width: 1365px) {
    font-size: 2.75em;
  }

  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    font-size: 2em;
  }

  @media only screen and (max-width: 767px) {
    font-size: 1.5em;
  }
}

.Para1FourteenComponent {
  font-style: normal;
  font-weight: 700;
  font-size: 3.375em;
  line-height: 98%;
  color: #000000;
  margin: 0;

  @media only screen and (min-width: 768px) and (max-width: 1365px) {
    font-size: 2em;
  }

  @media only screen and (max-width: 767px) {
    font-size: 1.5em;
  }
}

.Para2ThirdComponent {
  font-style: normal;
  font-weight: normal;
  color: #000000;
  text-align: left;
  font-size: 1em;
  line-height: 134%;
  margin: 0;
  padding-top: 10px;

  @media only screen and (max-width: 1024px) and (max-width: 1365px) {
    font-size: 14px;
  }

  @media only screen and (max-width: 1023px) {
    font-size: 12px;
  }
}

.Para2FourteenComponent {
  font-style: normal;
  font-weight: normal;
  color: #000000;
  text-align: left;
  font-size: 1em;
  line-height: 134%;
  margin: 0;
  padding-top: 10px;

  @media only screen and (max-width: 1024px) and (max-width: 1365px) {
    font-size: 14px;
    width: 80%;
  }

  @media only screen and (max-width: 1023px) {
    font-size: 12px;
  }
}

.TitleSecondBlogHome {
  font-style: normal;
  font-weight: 700;
  font-size: 3.375em;
  line-height: 98%;
  color: #000000;
  margin: 0;
  text-align: center;

  @media only screen and (min-width: 768px) and (max-width: 1365px) {
    font-size: 2.75em;
  }

  @media only screen and (max-width: 767px) {
    font-size: 1.5em;
  }
}

.SubTitle2BlogHome {
  font-style: normal;
  font-weight: normal;
  color: #000000;
  text-align: left;
  position: relative;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 50px;
  font-size: 18px;

  @media only screen and (max-width: 1023px) {
    font-size: 14px;
    padding-left: 20px;
    padding-right: 60px;
  }
}

.SubTitleBlogHome {
  font-style: normal;
  font-weight: normal;
  color: #000000;
  text-align: left;
  position: relative;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 50px;
  font-size: 18px;

  @media only screen and (max-width: 1023px) {
    font-size: 14px;
    padding-left: 20px;
    padding-right: 60px;
  }
}

.marginTopBottomBlog {
  @media only screen and (min-width: 1024px) {
    margin-top: 120px;
  }

  @media only screen and (min-width: 568px) and (max-width: 1023px) {
    margin-top: 100px;
  }

  @media only screen and (max-width: 567px) {
    margin-top: 100px;
  }
}

.blogPageTitle {

  font-style: normal;
  font-weight: 500;
  color: #000000;
  text-align: left;
  font-size: 38px;
  line-height: 45px;
  margin: 0;
  padding-bottom: 15px;

  @media only screen and (max-width: 1024px) {
    font-size: 24px;
    line-height: 31px;
    padding-bottom: 25px;
  }
}

.imgSelectedBlogMob {
  @media only screen and (max-width: 567px) {
    margin-bottom: 25px;
  }

  @media only screen and (min-width: 568px) {}
}

.selectedBlogContent {
  font-style: normal;
  font-weight: 400;
  color: #0D0D0D;
  text-align: left;
  font-size: 16px;
  line-height: 23px;
  margin: 0;
  padding-left: 10px;

  @media only screen and (max-width: 1024px) {
    font-size: 12px;
    line-height: 21px;
  }
}

.selectedBlogContent1 {
  font-style: normal;
  font-weight: 400;
  color: #2B2D38;
  text-align: left;
  font-size: 12px;
  line-height: 21px;
  margin: 0;
  padding-left: 10px;
}

.BlogPageSubAuthor {
  font-style: normal;
  font-weight: 600;
  color: #2B2D38;
  text-align: left;
  font-size: 12px;
  line-height: 16px;
  margin: 0;
}

.dotBlog {
  display: inline-block;
  width: 3px;
  /* Adjust the width of the dot as needed */
  height: 3px;
  /* Adjust the height of the dot as needed */
  background-color: #646B81;
  /* Change the color of the dot if needed */
  border-radius: 50%;
  /* Ensures the dot is round */
  margin: 2px 10px;
  /* Adjust the spacing between the dot and spans */
}

.BlogPageSubH1 {
  font-style: normal;
  font-weight: 500;
  color: #2B2D38;
  text-align: left;
  font-size: 24px;
  line-height: 26px;
  margin: 0;

  @media only screen and (min-width: 568px) and (max-width: 1024px) {
    font-size: 20px;
    line-height: 24px;
  }

  @media only screen and (max-width: 567px) {
    display: none;
  }
}

.blogPageSub2 {

  font-style: normal;
  font-weight: 500;
  color: #000000;
  text-align: left;
  font-size: 38px;
  line-height: 45px;
  margin: 0;

  @media only screen and (min-width: 568px) and (max-width: 1024px) {
    font-size: 28px;
    line-height: 34px;
  }

  @media only screen and (max-width: 567px) {
    display: none;
  }
}

.BlogPageSubLi {

  font-style: normal;
  font-weight: 400;
  color: #1c1e29;
  text-align: left;
  font-size: 16px;
  line-height: 20px;
  margin: 0;

  @media only screen and (max-width: 567px) {
    font-size: 14px;
    display: none;
  }
}

.UListDiv {
  list-style-type: none;
  padding: 0;
}

.pageContainerBlog {
  display: grid;
  z-index: 10;

  @media only screen and (min-width: 1366px) {
    grid-template-columns: 65% auto;
    column-gap: 60px;
  }

  @media only screen and (min-width: 568px) and (max-width: 1365px) {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 40px;
  }

  @media only screen and (max-width: 567px) {
    display: none;
  }
}

.Otherblogdiv {
  @media only screen and (min-width: 1024px) {
    margin-top: 60px;
    margin-bottom: 40px;
  }

  @media only screen and (min-width: 568px) and (max-width: 1023px) {
    margin-top: 55px;
    margin-bottom: 35px;
  }

  @media only screen and (max-width: 567px) {
    display: none;
  }
}

.blogPageGreet {
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0px 15px 40px 0px rgba(166, 173, 201, 0.2);
}

.SubTitleTopStory {

  font-style: normal;
  font-weight: 500;
  color: #353535;
  text-align: left;
  position: relative;
  margin: 0;
  font-size: 16px;
  line-height: 24px;

  @media only screen and (max-width: 567px) {
    padding-bottom: 10px;
  }
}

.SubContentTopStory {

  font-style: normal;
  font-weight: normal;
  color: #575757;
  text-align: left;
  position: relative;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 14px;
}

.para1SixteenComponent {

  font-style: normal;
  font-weight: 700;
  font-size: 3.375em;
  line-height: 98%;
  color: #000000;
  margin: 0;
  text-align: center;

  @media only screen and (min-width: 768px) and (max-width: 1365px) {
    font-size: 2.75em;
  }

  @media only screen and (max-width: 767px) {
    font-size: 1.5em;
  }
}

.para2SixteenComponent {

  font-style: normal;
  font-weight: normal;
  color: #000000;
  text-align: center;
  font-size: 1em;
  line-height: 134%;
  margin: 0;
  padding-top: 18px;
  margin-left: 180px;
  margin-right: 180px;

  @media only screen and (max-width: 1024px) and (max-width: 1365px) {
    font-size: 14px;
  }

  @media only screen and (max-width: 1023px) {
    font-size: 12px;
    margin-left: 40px;
    margin-right: 40px;
  }
}

.pageContainerFourteenComp {
  display: grid;
  grid-template-columns: 40% auto;

  @media only screen and (max-width: 567px) {
    grid-template-columns: repeat(1, 1fr);
    background: #ffffff;
    border-radius: 20px;
  }

  box-shadow: 0px 0px 41.5654px rgba(0, 0, 0, 0.02);
}

.greetFourteenComp {
  position: relative;

  video {
    @media only screen and (max-width: 567px) {
      border-radius: 20px 20px 0px 0px;
    }

    @media only screen and (min-width: 568px) {
      border-radius: 20px 0px 0px 20px;
    }
  }
}

.greet1FourteenComp {
  @media only screen and (min-width: 568px) {
    position: absolute;
    left: 62%;
    top: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
  }

  @media only screen and (max-width: 567px) {
    margin-top: 25px;
    margin-bottom: 40px;
    margin-left: 40px;
    margin-right: 40px;
  }
}

.greet2FourteenComp {
  position: relative;

  @media only screen and (max-width: 567px) {
    border-radius: 0px 0px 20px 20px;
  }

  @media only screen and (min-width: 568px) {
    border-radius: 0px 20px 20px 0px;
  }
}

#StyledTab {
  flex: 1;
  height: 100%;

  button {

    font-style: normal;
    font-weight: 700;
    cursor: pointer;
    transition: font-weight 0.3s;
    font-size: 18px;

    @media screen and (max-width: 1023px) {
      font-size: 12px;
    }

    color: #000000;
    border-top: none;
    border-left: none;
    border-right: none;
    width: 100%;
    height: 100%;
    padding-bottom: 10px;
    border-bottom-width: 1px;
    background: transparent;
  }
}

#StyledTabs {
  position: relative;
  list-style: none;
  height: 30px;
}

#StyledTabIndicator {
  position: absolute;
  top: 100%;
  left: 0;
  border-top-style: solid;
  border-top-width: 4px;
}

#StyledOuterSliders {
  overflow: hidden;
}

#StyledSliders {
  display: flex;
  flex-wrap: no-wrap;
  width: 100%;
  div {
    flex-shrink: 0;
    width: 100%;
  }
}

.pageContainer12Comp {
  display: grid;
  grid-template-columns: auto auto;

  @media only screen and (max-width: 567px) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media only screen and (min-width: 568px) and (max-width: 1023px) {
    column-gap: 25px;
  }

  column-gap: 40px;
  row-gap: 15px;
}

.Para3TwelveComp {

  font-style: normal;
  font-weight: normal;
  color: #ffffff;
  text-align: left;
  font-size: 1em;
  line-height: 134%;
  margin: 0;

  @media only screen and (max-width: 1024px) and (max-width: 1365px) {
    font-size: 14px;
  }

  @media only screen and (max-width: 1023px) {
    font-size: 12px;
  }
}

.Para4TwelveComp {

  font-style: normal;
  font-weight: 700;
  color: #000000;
  text-align: left;
  font-size: 24px;
  line-height: 135%;
  margin: 0;

  @media only screen and (max-width: 1023px) {
    font-size: 18px;
  }
}

.ParaDiv {
  position: absolute;
  bottom: 40px;
  left: 40px;
  width: 100%;

  @media only screen and (max-width: 1023px) {
    bottom: 30px;
    left: 30px;
  }
}

.BackgroundImg {
  background-image: url("https://d3mhjg7evfw0lx.cloudfront.net/twelveComp1.png");
  background-image: -webkit-image-set(url("https://d3mhjg7evfw0lx.cloudfront.net/twelveComp1.png") 1x,
      url("https://d3mhjg7evfw0lx.cloudfront.net/twelveComp1.avif") 1x,
      url("https://d3mhjg7evfw0lx.cloudfront.net/twelveComp1.webp") 1x);
  background-image: image-set(url("https://d3mhjg7evfw0lx.cloudfront.net/twelveComp1.png") 1x,
      url("https://d3mhjg7evfw0lx.cloudfront.net/twelveComp1.avif") 1x,
      url("https://d3mhjg7evfw0lx.cloudfront.net/twelveComp1.webp") 1x);

  background-position: center;
  background-size: cover;
  height: 100%;
  width: 100%;
  border-radius: 20px;
}

.BackgroundImg1 {
  background-image: url("https://d3mhjg7evfw0lx.cloudfront.net/Desktop12.png");
  background-image: -webkit-image-set(url("https://d3mhjg7evfw0lx.cloudfront.net/Desktop12.png") 1x,
      url("https://d3mhjg7evfw0lx.cloudfront.net/Desktop12.avif") 1x,
      url("https://d3mhjg7evfw0lx.cloudfront.net/Desktop12.webp") 1x);
  background-image: image-set(url("https://d3mhjg7evfw0lx.cloudfront.net/Desktop12.png") 1x,
      url("https://d3mhjg7evfw0lx.cloudfront.net/Desktop12.avif") 1x,
      url("https://d3mhjg7evfw0lx.cloudfront.net/Desktop12.webp") 1x);

  background-position: center;
  background-size: cover;
  height: 100%;
  width: 100%;
  border-radius: 20px;
}

.ParaThirteenComponent {

  font-style: normal;
  font-weight: 400;
  color: #000000;
  text-align: left;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  width: 100%;

  @media only screen and (max-width: 1024px) and (max-width: 1365px) {
    font-size: 14px;
    line-height: 18px;
  }

  @media only screen and (max-width: 1023px) {
    font-size: 12px;
    line-height: 16px;

  }
}





.pageContainerSixCompMobile {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  row-gap: 15px;
}

.greetSixCompMobile {
  position: relative;
}

.ParaSixCoompMobile {

  font-style: normal;
  font-weight: normal;
  color: #ffffff;
  text-align: left;
  line-height: 135%;
  margin: 0;

  @media only screen and (max-width: 567px) {
    font-size: 12px;
  }
}

.BackgroundImg1SixCompMob {
  background-image: url("https://d3mhjg7evfw0lx.cloudfront.net/sixthComp1.png");
  background-image: -webkit-image-set(url("https://d3mhjg7evfw0lx.cloudfront.net/sixthComp1.png") 1x,
      url("https://d3mhjg7evfw0lx.cloudfront.net/sixthComp1.avif") 1x,
      url("https://d3mhjg7evfw0lx.cloudfront.net/sixthComp1.webp") 1x);
  background-image: image-set(url("https://d3mhjg7evfw0lx.cloudfront.net/sixthComp1.png") 1x,
      url("https://d3mhjg7evfw0lx.cloudfront.net/sixthComp1.avif") 1x,
      url("https://d3mhjg7evfw0lx.cloudfront.net/sixthComp1.webp") 1x);
  background-position: center;
  background-size: cover;
  height: 100%;
  width: 100%;
  border-radius: 20px;
}

.BackgroundImg2SixCompMob {
  background-image: url("https://d3mhjg7evfw0lx.cloudfront.net/sixthComp2.png");
  background-image: -webkit-image-set(url("https://d3mhjg7evfw0lx.cloudfront.net/sixthComp2.png") 1x,
      url("https://d3mhjg7evfw0lx.cloudfront.net/sixthComp2.avif") 1x,
      url("https://d3mhjg7evfw0lx.cloudfront.net/sixthComp2.webp") 1x);
  background-image: image-set(url("https://d3mhjg7evfw0lx.cloudfront.net/sixthComp2.png") 1x,
      url("https://d3mhjg7evfw0lx.cloudfront.net/sixthComp2.avif") 1x,
      url("https://d3mhjg7evfw0lx.cloudfront.net/sixthComp2.webp") 1x);
  background-position: center;
  background-size: cover;
  height: 100%;
  width: 100%;
  border-radius: 20px;
}

.BackgroundImg3SixCompMob {
  background-image: url("https://d3mhjg7evfw0lx.cloudfront.net/sixthComp3.png");
  background-image: -webkit-image-set(url("https://d3mhjg7evfw0lx.cloudfront.net/sixthComp3.png") 1x,
      url("https://d3mhjg7evfw0lx.cloudfront.net/sixthComp3.avif") 1x,
      url("https://d3mhjg7evfw0lx.cloudfront.net/sixthComp3.webp") 1x);
  background-image: image-set(url("https://d3mhjg7evfw0lx.cloudfront.net/sixthComp3.png") 1x,
      url("https://d3mhjg7evfw0lx.cloudfront.net/sixthComp3.avif") 1x,
      url("https://d3mhjg7evfw0lx.cloudfront.net/sixthComp3.webp") 1x);
  background-position: center;
  background-size: cover;
  height: 100%;
  width: 100%;
  border-radius: 20px;
}

.pageContainerSixthComp {
  display: grid;
  grid-template-columns: 48% auto;

  @media only screen and (max-width: 567px) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media only screen and (min-width: 568px) and (max-width: 1023px) {
    column-gap: 25px;
  }

  column-gap: 40px;
  grid-auto-flow: column;
}

.pageContainer1SixthComp {
  display: grid;
  grid-template-columns: repeat(1, 1fr);

  @media only screen and (min-width: 1024px) {
    row-gap: 40px;
    column-gap: 40px;
  }

  @media only screen and (min-width: 568px) and (max-width: 1023px) {
    row-gap: 25px;
    column-gap: 25px;
  }
}

.greetSixthComp {
  position: relative;
}

.ParaDivS {
  position: absolute;
  bottom: 40px;
  left: 40px;
  width: 100%;

  @media only screen and (max-width: 1023px) {
    bottom: 30px;
    left: 30px;
  }
}

.para3sixthComp {

  font-style: normal;
  font-weight: normal;
  color: #ffffff;
  text-align: left;
  font-size: 1em;
  line-height: 134%;
  margin: 0;

  @media only screen and (max-width: 1024px) and (max-width: 1365px) {
    font-size: 14px;
  }

  @media only screen and (max-width: 1023px) {
    font-size: 12px;
  }
}

.paraDivSixComp {
  position: absolute;
  top: 40px;
  left: 40px;
  width: 100%;

  @media only screen and (max-width: 1023px) {
    top: 30px;
    left: 30px;
  }
}

.paraDiv1SixComp {
  position: absolute;
  top: 40px;
  left: 40px;
  width: 100%;

  @media only screen and (max-width: 1023px) {
    top: 30px;
    left: 30px;
  }
}

.BackgroundImg1SixthComp {
  background-image: url("https://d3mhjg7evfw0lx.cloudfront.net/sixthCompOne.png");
  background-image: -webkit-image-set(url("https://d3mhjg7evfw0lx.cloudfront.net/sixthCompOne.png") 1x,
      url("https://d3mhjg7evfw0lx.cloudfront.net/sixthCompOne.avif") 1x,
      url("https://d3mhjg7evfw0lx.cloudfront.net/sixthCompOne.webp") 1x);
  background-image: image-set(url("https://d3mhjg7evfw0lx.cloudfront.net/sixthCompOne.png") 1x,
      url("https://d3mhjg7evfw0lx.cloudfront.net/sixthCompOne.avif") 1x,
      url("https://d3mhjg7evfw0lx.cloudfront.net/sixthCompOne.webp") 1x);
  background-position: center;
  background-size: cover;
  height: 100%;
  width: 100%;
  border-radius: 20px;
}

.BackgroundImg2SixthComp {
  background-image: url("https://d3mhjg7evfw0lx.cloudfront.net/sixthComp2.png");
  background-image: -webkit-image-set(url("https://d3mhjg7evfw0lx.cloudfront.net/sixthComp2.png") 1x,
      url("https://d3mhjg7evfw0lx.cloudfront.net/sixthComp2.avif") 1x,
      url("https://d3mhjg7evfw0lx.cloudfront.net/sixthComp2.webp") 1x);
  background-image: image-set(url("https://d3mhjg7evfw0lx.cloudfront.net/sixthComp2.png") 1x,
      url("https://d3mhjg7evfw0lx.cloudfront.net/sixthComp2.avif") 1x,
      url("https://d3mhjg7evfw0lx.cloudfront.net/sixthComp2.webp") 1x);
  background-position: center;
  background-size: cover;
  height: 100%;
  width: 100%;
  border-radius: 20px;
}

.BackgroundImg3SixthComp {
  background-image: url("https://d3mhjg7evfw0lx.cloudfront.net/sixthComp3.png");
  background-image: -webkit-image-set(url("https://d3mhjg7evfw0lx.cloudfront.net/sixthComp3.png") 1x,
      url("https://d3mhjg7evfw0lx.cloudfront.net/sixthComp3.avif") 1x,
      url("https://d3mhjg7evfw0lx.cloudfront.net/sixthComp3.webp") 1x);
  background-image: image-set(url("https://d3mhjg7evfw0lx.cloudfront.net/sixthComp3.png") 1x,
      url("https://d3mhjg7evfw0lx.cloudfront.net/sixthComp3.avif") 1x,
      url("https://d3mhjg7evfw0lx.cloudfront.net/sixthComp3.webp") 1x);
  background-position: center;
  background-size: cover;
  height: 100%;
  width: 100%;
  border-radius: 20px;
}

.paraSevenComp {

  font-style: normal;
  font-weight: 700;
  line-height: 118%;
  color: #000000;
  margin: 0;
  font-size: 1.5em;
}

.para2SevenComp {

  font-style: normal;
  font-weight: normal;
  color: #000000;
  text-align: left;
  line-height: 135%;
  margin: 0;
  padding-top: 10px;
  font-size: 12px;
}

.BackgroundImgSevenComp {
  background-image: url("https://d3mhjg7evfw0lx.cloudfront.net/sevenCompMobNew.png");
  background-image: -webkit-image-set(url("https://d3mhjg7evfw0lx.cloudfront.net/sevenCompMobNew.png") 1x,
      url("https://d3mhjg7evfw0lx.cloudfront.net/sevenCompMobNew_1.avif") 1x,
      url("https://d3mhjg7evfw0lx.cloudfront.net/sevenCompMobNew.webp") 1x);
  background-image: image-set(url("https://d3mhjg7evfw0lx.cloudfront.net/sevenCompMobNew.png") 1x,
      url("https://d3mhjg7evfw0lx.cloudfront.net/sevenCompMobNew_1.avif") 1x,
      url("https://d3mhjg7evfw0lx.cloudfront.net/sevenCompMobNew.webp") 1x);
  background-position: center;
  background-size: cover;
  height: 100%;
  width: 100%;
  border-radius: 20px;
}

.para2SevenDesk {

  font-style: normal;
  font-weight: normal;
  color: #000000;
  text-align: left;
  font-size: 1em;
  line-height: 134%;
  margin: 0;
  padding-top: 10px;

  @media only screen and (max-width: 1024px) and (max-width: 1365px) {
    font-size: 14px;
  }

  @media only screen and (max-width: 1023px) {
    font-size: 12px;
  }
}

.pararingFitSuccess {

  font-style: normal;
  font-weight: 700;
  color: #000000;
  text-align: center;
  font-size: 42px;

  @media only screen and (min-width: 568px) and (max-width: 1023px) {
    font-size: 36px;
  }

  @media only screen and (max-width: 567px) {
    font-size: 32px;
  }

  line-height: 98%;
  margin: 0;
}

.subTitleringfitSuccess {

  font-style: normal;
  font-weight: normal;
  color: #000000;
  text-align: center;
  font-size: 16px;

  @media only screen and (min-width: 568px) and (max-width: 1023px) {
    font-size: 12px;
  }

  @media only screen and (max-width: 567px) {
    font-size: 14px;
  }
}

.para1Ringfit {

  font-style: normal;
  font-weight: 700;
  color: #000000;
  text-align: left;
  font-size: 38px;
  padding-bottom: 25px;

  @media only screen and (min-width: 568px) and (max-width: 1023px) {
    font-size: 34px;
  }

  @media only screen and (max-width: 567px) {
    font-size: 30px;
  }

  line-height: 98%;
  margin: 0;
}

.para2Ringfit {

  font-style: normal;
  font-weight: normal;
  color: #000000;
  text-align: left;
  font-size: 18px;
  line-height: 145%;
  margin: 0;
  padding-bottom: 25px;

  @media only screen and (min-width: 568px) and (max-width: 1023px) {
    font-size: 16px;
  }

  @media only screen and (max-width: 567px) {
    font-size: 14px;
  }
}

.paraspanRIngfit {

  font-style: normal;
  font-weight: normal;
  color: red;
  text-align: left;
  font-size: 12px;
  line-height: 145%;
  margin: 0;
}

.backImageRingsizeconfirm {
  @media only screen and (min-width: 1024px) {
    background-image: url("https://d3mhjg7evfw0lx.cloudfront.net/ringFitDesktop.png");
    background-image: -webkit-image-set(url("https://d3mhjg7evfw0lx.cloudfront.net/ringFitDesktop.png") 1x,
        url("https://d3mhjg7evfw0lx.cloudfront.net/ringFitDesktop.avif") 1x,
        url("https://d3mhjg7evfw0lx.cloudfront.net/ringFitDesktop.webp") 1x);
    background-image: image-set(url("https://d3mhjg7evfw0lx.cloudfront.net/ringFitDesktop.png") 1x,
        url("https://d3mhjg7evfw0lx.cloudfront.net/ringFitDesktop.avif") 1x,
        url("https://d3mhjg7evfw0lx.cloudfront.net/ringFitDesktop.webp") 1x);

    background-position: center;
  }

  @media only screen and (max-width: 1023px) {
    background-image: url("https://d3mhjg7evfw0lx.cloudfront.net/ringFitMob.png");
    background-image: -webkit-image-set(url("https://d3mhjg7evfw0lx.cloudfront.net/ringFitMob.png") 1x,
        url("https://d3mhjg7evfw0lx.cloudfront.net/ringFitMob.avif") 1x,
        url("https://d3mhjg7evfw0lx.cloudfront.net/ringFitMob.webp") 1x);
    background-image: image-set(url("https://d3mhjg7evfw0lx.cloudfront.net/ringFitMob.png") 1x,
        url("https://d3mhjg7evfw0lx.cloudfront.net/ringFitMob.avif") 1x,
        url("https://d3mhjg7evfw0lx.cloudfront.net/ringFitMob.webp") 1x);
  }

  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
}

.paraContactUsPopUp {

  font-style: normal;
  font-weight: bold;
  color: #000000;
  text-align: left;
  font-size: 18px;
  line-height: 145%;

  @media only screen and (min-width: 568px) and (max-width: 1023px) {
    font-size: 16px;
  }

  @media only screen and (max-width: 567px) {
    font-size: 14px;
  }
}

.paraSpanContactUsPopUp {

  font-style: normal;
  font-weight: normal;
  color: red;
  text-align: left;
  font-size: 12px;
  line-height: 145%;
  margin: 0;
}



//   background-image: ${({ imageUrl }) => `url(${imageUrl})`};

.pageContainerRedDot {
  display: grid;
  grid-template-columns: 50% 50%;
  border-radius: 20px;

  @media only screen and (max-width: 567px) {
    grid-template-columns: repeat(1, 1fr);
    background: #ffffff;
  }

  box-shadow: 0px 0px 41.5654px rgba(0, 0, 0, 0.02);
}

.greetReddot {
  position: relative;

  video {
    @media only screen and (max-width: 567px) {
      border-radius: 20px 20px 0px 0px;
    }

    @media only screen and (min-width: 568px) {
      border-radius: 20px 0px 0px 20px;
    }
  }
}

.greet2Reddot {
  position: relative;

  @media only screen and (max-width: 567px) {
    border-radius: 0px 0px 20px 20px;
  }

  @media only screen and (min-width: 568px) {
    border-radius: 0px 20px 20px 0px;
  }
}

.greet1Reddot {
  @media only screen and (min-width: 568px) {
    position: absolute;
    left: 62%;
    top: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
  }

  @media only screen and (max-width: 567px) {
    margin-top: 25px;
    margin-bottom: 40px;
    margin-left: 40px;
    margin-right: 40px;
  }
}

.buttonDivRedot {
  position: absolute;
  bottom: 10px;
  right: 10px;
  cursor: pointer;
  z-index: 100;
}

.para1RedDot {

  font-style: normal;
  font-weight: 700;
  font-size: 3.375em;
  line-height: 98%;
  color: #000000;
  margin: 0;

  @media only screen and (min-width: 1024px) and (max-width: 1365px) {
    font-size: 2.75em;
  }

  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    font-size: 2em;
  }

  @media only screen and (max-width: 767px) {
    font-size: 1.5em;
  }
}

.para2RedDot {

  font-style: normal;
  font-weight: normal;
  color: #000000;
  text-align: left;
  font-size: 1em;
  line-height: 134%;
  margin: 0;
  padding-top: 10px;
  word-wrap: break-word;
  width: 80%;

  @media only screen and (max-width: 1024px) and (max-width: 1365px) {
    font-size: 14px;
  }

  @media only screen and (max-width: 1023px) {
    font-size: 12px;
  }
}

.BackgroundImgRedDot {
  background-image: url("https://d3mhjg7evfw0lx.cloudfront.net/redotRing.png");
  background-image: -webkit-image-set(url("https://d3mhjg7evfw0lx.cloudfront.net/redotRing.png") 1x,
      url("https://d3mhjg7evfw0lx.cloudfront.net/redotRing.avif") 1x,
      url("https://d3mhjg7evfw0lx.cloudfront.net/redotRing.webp") 1x);
  background-image: image-set(url("https://d3mhjg7evfw0lx.cloudfront.net/redotRing.png") 1x,
      url("https://d3mhjg7evfw0lx.cloudfront.net/redotRing.avif") 1x,
      url("https://d3mhjg7evfw0lx.cloudfront.net/redotRing.webp") 1x);

  background-position: center;
  background-size: cover;
  height: 100%;
  width: 100%;
  border-radius: 20px 0px 0px 20px;
}

.para1PaymentSuccess {

  font-style: normal;
  font-weight: 700;
  color: #000000;
  text-align: center;
  font-size: 42px;

  @media only screen and (min-width: 568px) and (max-width: 1023px) {
    font-size: 36px;
  }

  @media only screen and (max-width: 567px) {
    font-size: 32px;
  }

  line-height: 98%;
  margin: 0;
}

.subtitlePaymentSuccess {

  font-style: normal;
  font-weight: normal;
  color: #000000;
  text-align: center;
  font-size: 16px;

  @media only screen and (min-width: 568px) and (max-width: 1023px) {
    font-size: 12px;
  }

  @media only screen and (max-width: 567px) {
    font-size: 14px;
  }
}

.MarginDivSizingPopup {
  @media only screen and (min-width: 1024px) {
    margin-left: 9vw;
    margin-right: 9vw;
  }

  @media only screen and (min-width: 568px) and (max-width: 1023px) {
    margin-left: 15vw;
    margin-right: 15vw;
  }

  @media only screen and (max-width: 567px) {
    margin-left: 6vw;
    margin-right: 6vw;
  }
}

.MarginTopBottomFirst1Sizingpage {
  @media only screen and (min-width: 1024px) {
    margin-top: 120px;
  }

  @media only screen and (min-width: 568px) and (max-width: 1023px) {
    margin-top: 100px;
  }

  @media only screen and (max-width: 567px) {
    margin-top: 100px;
  }
}

.sizingGuideResponsive1 {
  @media only screen and (min-width: 568px) {
    display: none;
  }

  @media only screen and (max-width: 568px) {
    display: block;
  }
}

.Para1SizingPagee {

  font-style: normal;
  font-weight: 500;
  color: #000000;
  text-align: left;
  font-size: 36px;
  line-height: 145%;
  padding-bottom: 10px;

  @media only screen and (min-width: 568px) and (max-width: 1023px) {
    font-size: 32px;
    padding-bottom: 10px;
  }

  @media only screen and (max-width: 567px) {
    font-size: 24px;
    padding-bottom: 10px;
  }
}

.Para2SizingPagee {

  font-style: normal;
  font-weight: 500;
  color: #000000;
  text-align: left;
  font-size: 1em;
  line-height: 145%;

  @media only screen and (min-width: 568px) and (max-width: 1023px) {
    font-size: 14px;
  }

  @media only screen and (max-width: 567px) {
    font-size: 12px;
  }
}

.ParaGo {

  font-style: normal;
  font-weight: 400;
  color: #000000;
  text-align: left;
  margin: 0;
  line-height: 133.9%;
  cursor: pointer;
  margin-bottom: 20px;

  @media only screen and (min-width: 1024px) {
    font-size: 16px;
  }

  @media only screen and (min-width: 568px) and (max-width: 1023px) {
    font-size: 14px;
  }

  @media only screen and (max-width: 567px) {
    font-size: 14px;
  }
}

.para1SuccessComp {

  font-style: normal;
  font-weight: 700;
  color: #000000;
  text-align: center;
  font-size: 32px;

  @media only screen and (min-width: 568px) and (max-width: 1023px) {
    font-size: 26px;
  }

  @media only screen and (max-width: 567px) {
    font-size: 28px;
  }

  line-height: 98%;
  margin: 0;
  padding-bottom: 40px;
}

.subTitleSuccessComp {

  font-style: normal;
  font-weight: normal;
  color: #000000;
  text-align: center;
  font-size: 16px;

  @media only screen and (min-width: 568px) and (max-width: 1023px) {
    font-size: 12px;
  }

  @media only screen and (max-width: 567px) {
    font-size: 14px;
  }

  padding-bottom: 40px;
  margin: 0;
}

.dhyanaTextSuccessComp {

  font-style: normal;
  font-weight: normal;
  color: #666666;
  margin: 0;
  line-height: 143.19%;

  @media only screen and (min-width: 1024px) {
    font-size: 16px;
    padding-top: 24px;
    padding-bottom: 40px;
  }

  @media only screen and (min-width: 568px) and (max-width: 1023px) {
    font-size: 14px;
    padding-top: 24px;
    padding-bottom: 40px;
  }

  @media only screen and (max-width: 567px) {
    font-size: 12px;
    padding-top: 28px;
    padding-bottom: 28px;
  }
}

.para3NineCompMob {

  font-style: normal;
  font-weight: normal;
  color: #ffffff;
  text-align: left;
  line-height: 135%;
  margin: 0;
  font-size: 12px;
}

.para4NineCompMob {

  font-style: normal;
  font-weight: normal;
  color: #ffffff;
  text-align: left;
  font-size: 18px;
  line-height: 135%;
  margin: 0;
}

.BackgroundImgNineCompMob {
  background-image: url("https://d3mhjg7evfw0lx.cloudfront.net/nineComp1.png");
  background-image: -webkit-image-set(url("https://d3mhjg7evfw0lx.cloudfront.net/nineComp1.png") 1x,
      url("https://d3mhjg7evfw0lx.cloudfront.net/nineComp1.avif") 1x,
      url("https://d3mhjg7evfw0lx.cloudfront.net/nineComp1.webp") 1x);
  background-image: image-set(url("https://d3mhjg7evfw0lx.cloudfront.net/nineComp1.png") 1x,
      url("https://d3mhjg7evfw0lx.cloudfront.net/nineComp1.avif") 1x,
      url("https://d3mhjg7evfw0lx.cloudfront.net/nineComp1.webp") 1x);

  background-position: center;
  background-size: cover;
  height: 100%;
  width: 100%;
  border-radius: 20px;
}

.BackgroundImg1NineCompMob {
  background-image: url("https://d3mhjg7evfw0lx.cloudfront.net/nineComp3.png");
  background-image: -webkit-image-set(url("https://d3mhjg7evfw0lx.cloudfront.net/nineComp3.png") 1x,
      url("https://d3mhjg7evfw0lx.cloudfront.net/nineComp3.avif") 1x,
      url("https://d3mhjg7evfw0lx.cloudfront.net/nineComp3.webp") 1x);
  background-image: image-set(url("https://d3mhjg7evfw0lx.cloudfront.net/nineComp3.png") 1x,
      url("https://d3mhjg7evfw0lx.cloudfront.net/nineComp3.avif") 1x,
      url("https://d3mhjg7evfw0lx.cloudfront.net/nineComp3.webp") 1x);

  background-position: center;
  background-size: cover;
  height: 100%;
  width: 100%;
  border-radius: 20px;
}

.BackgroundImg2NineCompMob {
  background-image: url("https://d3mhjg7evfw0lx.cloudfront.net/nineComp2.png");
  background-image: -webkit-image-set(url("https://d3mhjg7evfw0lx.cloudfront.net/nineComp2.png") 1x,
      url("https://d3mhjg7evfw0lx.cloudfront.net/nineComp2.avif") 1x,
      url("https://d3mhjg7evfw0lx.cloudfront.net/nineComp2.webp") 1x);
  background-image: image-set(url("https://d3mhjg7evfw0lx.cloudfront.net/nineComp2.png") 1x,
      url("https://d3mhjg7evfw0lx.cloudfront.net/nineComp2.avif") 1x,
      url("https://d3mhjg7evfw0lx.cloudfront.net/nineComp2.webp") 1x);

  background-position: center;
  background-size: cover;
  height: 100%;
  width: 100%;
  border-radius: 20px;
}

.PageContainerNineComp {
  display: grid;
  grid-template-columns: 48% auto;

  @media only screen and (max-width: 567px) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media only screen and (min-width: 568px) and (max-width: 1023px) {
    column-gap: 25px;
  }

  column-gap: 40px;
  grid-auto-flow: column;
}

.PageContainer1NineComp {
  display: grid;
  grid-template-columns: repeat(1, 1fr);

  @media only screen and (min-width: 1024px) {
    row-gap: 40px;
    column-gap: 40px;
  }

  @media only screen and (min-width: 568px) and (max-width: 1023px) {
    row-gap: 25px;
    column-gap: 25px;
  }
}

.para3NineComp {

  font-style: normal;
  font-weight: normal;
  color: #ffffff;
  text-align: left;
  font-size: 1em;
  line-height: 134%;
  margin: 0;

  @media only screen and (max-width: 1024px) and (max-width: 1365px) {
    font-size: 14px;
  }

  @media only screen and (max-width: 1023px) {
    font-size: 12px;
  }
}

.para4NineComp {

  font-style: normal;
  font-weight: 700;
  color: #ffffff;
  text-align: left;
  font-size: 24px;
  line-height: 135%;
  margin: 0;

  @media only screen and (max-width: 1023px) {
    font-size: 18px;
  }
}

.paraDivNineComp {
  position: absolute;
  bottom: 40px;
  left: 40px;
  width: 100%;

  @media only screen and (max-width: 1023px) {
    bottom: 30px;
    left: 30px;
  }
}

.paraDiv1NineComp {
  position: absolute;
  top: 40px;
  left: 40px;
  width: 100%;

  @media only screen and (max-width: 1023px) {
    top: 30px;
    left: 30px;
  }
}

.BackgroundImg1NineComp {
  background-image: url("https://d3mhjg7evfw0lx.cloudfront.net/nineComp1.png");
  background-image: -webkit-image-set(url("https://d3mhjg7evfw0lx.cloudfront.net/nineComp1.png") 1x,
      url("https://d3mhjg7evfw0lx.cloudfront.net/nineComp1.avif") 1x,
      url("https://d3mhjg7evfw0lx.cloudfront.net/nineComp1.webp") 1x);
  background-image: image-set(url("https://d3mhjg7evfw0lx.cloudfront.net/nineComp1.png") 1x,
      url("https://d3mhjg7evfw0lx.cloudfront.net/nineComp1.avif") 1x,
      url("https://d3mhjg7evfw0lx.cloudfront.net/nineComp1.webp") 1x);

  background-position: center;
  background-size: cover;
  height: 100%;
  width: 100%;
  border-radius: 20px;
}

.BackgroundImg2NineComp {
  background-image: url("https://d3mhjg7evfw0lx.cloudfront.net/nineComp2.png");
  background-image: -webkit-image-set(url("https://d3mhjg7evfw0lx.cloudfront.net/nineComp2.png") 1x,
      url("https://d3mhjg7evfw0lx.cloudfront.net/nineComp2.avif") 1x,
      url("https://d3mhjg7evfw0lx.cloudfront.net/nineComp2.webp") 1x);
  background-image: image-set(url("https://d3mhjg7evfw0lx.cloudfront.net/nineComp2.png") 1x,
      url("https://d3mhjg7evfw0lx.cloudfront.net/nineComp2.avif") 1x,
      url("https://d3mhjg7evfw0lx.cloudfront.net/nineComp2.webp") 1x);

  background-position: center;
  background-size: cover;
  height: 100%;
  width: 100%;
  border-radius: 20px;
}

.BackgroundImg3NineComp {
  background-image: url("https://d3mhjg7evfw0lx.cloudfront.net/nineComp3.png");
  background-image: -webkit-image-set(url("https://d3mhjg7evfw0lx.cloudfront.net/nineComp3.png") 1x,
      url("https://d3mhjg7evfw0lx.cloudfront.net/nineComp3.avif") 1x,
      url("https://d3mhjg7evfw0lx.cloudfront.net/nineComp3.webp") 1x);
  background-image: image-set(url("https://d3mhjg7evfw0lx.cloudfront.net/nineComp3.png") 1x,
      url("https://d3mhjg7evfw0lx.cloudfront.net/nineComp3.avif") 1x,
      url("https://d3mhjg7evfw0lx.cloudfront.net/nineComp3.webp") 1x);

  background-position: center;
  background-size: cover;
  height: 100%;
  width: 100%;
  border-radius: 20px;
}

.marginDivThirdCompOrder {
  @media only screen and (min-width: 1366px) {
    margin-left: 11vw;
    margin-right: 11vw;
  }

  @media only screen and (min-width: 568px) and (max-width: 1365px) {
    margin-left: 10vw;
    margin-right: 10vw;
  }

  @media only screen and (max-width: 567px) {
    margin-left: 6vw;
    margin-right: 6vw;
  }
}

.MarginDivThirdComp {
  @media only screen and (min-width: 1366px) {
    margin-left: 11vw;
    margin-right: 11vw;
  }

  @media only screen and (min-width: 568px) and (max-width: 1365px) {
    margin-left: 10vw;
    margin-right: 10vw;
  }

  @media only screen and (max-width: 567px) {
    margin-left: 6vw;
    margin-right: 6vw;
  }
}

.MarginTopBottomFirst1ThirdComp {
  @media only screen and (min-width: 1024px) {
    margin-top: 54px;
    padding-left: 80px;
    padding-right: 80px;
  }

  @media only screen and (min-width: 568px) and (max-width: 1023px) {
    margin-top: 46px;
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 40px;
  }

  @media only screen and (max-width: 567px) {
    margin-top: 50px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 30px;
  }

  .thirdFlex {
    p {
      width: 100%;
    }

    display: flex;
  }
}

.PageContainerThirdComp {
  display: grid;
  z-index: 10;
  grid-template-columns: repeat(3, 1fr);

  @media only screen and (min-width: 1024px) {
    display: grid;
  }

  @media only screen and (min-width: 568px) and (max-width: 1023px) {
    display: none;
  }

  @media only screen and (max-width: 567px) {
    display: none;
  }
}

.PageContainerTabThirdComp {
  @media only screen and (min-width: 1024px) {
    display: none;
  }

  @media only screen and (min-width: 568px) and (max-width: 1023px) {
    display: block;
  }

  @media only screen and (max-width: 567px) {
    display: none;
  }
}

.PageContainerMobThirdComp {
  @media only screen and (min-width: 1024px) {
    display: none;
  }

  @media only screen and (min-width: 568px) and (max-width: 1023px) {
    display: none;
  }

  @media only screen and (max-width: 567px) {
    display: block;
  }
}

.PageContainer2ThirdComp {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.PageContainer1ThirdComp {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  row-gap: 20px;
}

.Para1ThirdCOmp {

  text-align: center;
  font-style: normal;
  font-weight: 600;
  color: #000000;
  margin: 0;

  @media only screen and (min-width: 1024px) {
    font-size: 24px;
    margin-bottom: 32px;
  }

  @media only screen and (min-width: 568px) and (max-width: 1023px) {
    font-size: 21px;
    margin-bottom: 28px;
  }

  @media only screen and (max-width: 567px) {
    font-size: 24px;
    margin-bottom: 40px;
  }
}

.Para2ThirdComp {

  font-style: normal;
  font-weight: 500;
  color: #666666;
  margin: 0;

  @media only screen and (min-width: 1024px) {
    font-size: 22px;
    padding-bottom: 12px;
  }

  @media only screen and (min-width: 568px) and (max-width: 1023px) {
    font-size: 20px;
    padding-bottom: 12px;
  }

  @media only screen and (max-width: 567px) {
    font-size: 14px;
    font-weight: 500;
  }
}

.Para1SizingPoopup {

  font-style: normal;
  font-weight: 500;
  color: #000000;
  text-align: left;
  font-size: 24px;
  line-height: 145%;
  padding-bottom: 10px;

  @media only screen and (min-width: 568px) and (max-width: 1023px) {
    font-size: 22px;
    padding-bottom: 10px;
  }

  @media only screen and (max-width: 567px) {
    font-size: 21px;
    padding-bottom: 10px;
  }
}

.Para2SizingPoopup {

  font-style: normal;
  font-weight: 500;
  color: #000000;
  text-align: left;
  font-size: 14px;
  line-height: 145%;

  @media only screen and (min-width: 568px) and (max-width: 1023px) {
    font-size: 14px;
  }

  @media only screen and (max-width: 567px) {
    font-size: 12px;
  }
}

.PopUpDivSizing {
  @media only screen and (min-width: 568px) {
    margin: 45px;
  }

  @media only screen and (max-width: 567px) {
    margin: 25px;
  }
}

.Para1ShippingComp {

  font-style: normal;
  font-weight: 500;
  color: #000000;
  text-align: left;
  font-size: 36px;
  line-height: 145%;
  margin: 0;
  padding-bottom: 35px;

  @media only screen and (min-width: 568px) and (max-width: 1023px) {
    font-size: 32px;
    padding-bottom: 28px;
  }

  @media only screen and (max-width: 567px) {
    font-size: 24px;
    padding-bottom: 25px;
  }
}

.Para2ShippingComp {

  font-style: normal;
  font-weight: 500;
  color: #000000;
  text-align: left;
  font-size: 1em;
  line-height: 145%;
  margin: 0;
  padding-bottom: 15px;

  @media only screen and (min-width: 568px) and (max-width: 1023px) {
    font-size: 14px;
    padding-bottom: 15px;
  }

  @media only screen and (max-width: 567px) {
    font-size: 12px;
    padding-bottom: 10px;
  }
}

.MarginDivShippingComp {
  @media only screen and (min-width: 1366px) {
    margin-left: 11vw;
    margin-right: 11vw;
  }

  @media only screen and (min-width: 1024px) and (max-width: 1365px) {
    margin-left: 9vw;
    margin-right: 9vw;
  }

  @media only screen and (min-width: 568px) and (max-width: 1023px) {
    margin-left: 15vw;
    margin-right: 15vw;
  }

  @media only screen and (max-width: 567px) {
    margin-left: 6vw;
    margin-right: 6vw;
  }
}

.MarginTopBottomFirst1ShippinComp {
  @media only screen and (min-width: 1024px) {
    margin-top: 150px;
  }

  @media only screen and (min-width: 568px) and (max-width: 1023px) {
    margin-top: 60px;
  }

  @media only screen and (max-width: 567px) {
    margin-top: 80px;
  }
}

.MarginTopBottomFirst1ShippinComp {
  @media only screen and (min-width: 1024px) {
    margin-top: 40px;
  }

  @media only screen and (min-width: 568px) and (max-width: 1023px) {
    margin-top: 30px;
  }

  @media only screen and (max-width: 567px) {
    margin-top: 25px;
  }
}

.Para2SelectCountryPopup {

  font-style: normal;
  font-weight: 500;
  color: #000000;
  text-align: center;
  font-size: 24px;
  line-height: 145%;

  @media only screen and (min-width: 568px) and (max-width: 1023px) {
    font-size: 21px;
  }

  @media only screen and (max-width: 567px) {
    font-size: 18px;
  }
}

.marginDivSecondCompOrder {
  @media only screen and (min-width: 1024px) {
    margin-left: 9vw;
    margin-right: 9vw;
  }

  @media only screen and (min-width: 568px) and (max-width: 1023px) {
    margin-left: 15vw;
    margin-right: 15vw;
  }

  @media only screen and (max-width: 567px) {
    margin-left: 6vw;
    margin-right: 6vw;
  }
}

.MarginTopBottomFirst1SecondCompOrder {
  @media only screen and (min-width: 1024px) {
    margin-top: 80px;
  }

  @media only screen and (min-width: 568px) and (max-width: 1023px) {
    margin-top: 70px;
  }

  @media only screen and (max-width: 567px) {
    margin-top: 50px;
  }
}

.PageContainerSecondCompoOrder {
  display: grid;
  z-index: 10;
  grid-template-columns: repeat(3, 1fr);
  background: #000000;
  color: #ffffff;
  box-shadow: 0px 0px 41.5338px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  padding-top: 100px;
  padding-bottom: 100px;
  padding-left: 70px;
  padding-right: 70px;

  @media only screen and (min-width: 1024px) {
    display: grid;
  }

  @media only screen and (min-width: 568px) and (max-width: 1023px) {
    display: none;
  }

  @media only screen and (max-width: 567px) {
    display: none;
  }
}

.PageContainerMobSecondCompOrder {
  background: #000000;
  color: #ffffff;
  box-shadow: 0px 0px 41.5338px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  padding-top: 50px;
  padding-bottom: 50px;
  padding-left: 45px;
  padding-right: 45px;

  @media only screen and (min-width: 1024px) {
    display: none;
  }

  @media only screen and (min-width: 568px) and (max-width: 1023px) {
    display: block;
  }

  @media only screen and (max-width: 567px) {
    display: block;
  }
}

.PageContainer1SecondCompoOrder {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  row-gap: 90px;
}


.content-wrapper-selected-blog {
  margin: 0 auto;
  max-width: 1440px;
  overflow: hidden;

  @media screen and (min-width: 3072px) {
    margin-top: 10%;
    margin-bottom: 10%;
  }

  @media screen and (min-width: 1920px) and (max-width: 3071px) {
    margin-top: 7%;
    margin-bottom: 7%;
  }

  @media screen and (min-width: 1440px) and (max-width: 1919px) {
    margin-top: 120px;
  }

  @media screen and (min-width: 1024px) and (max-width: 1439px) {
    margin-top: 120px;
  }

  @media screen and (min-width: 568px) and (max-width: 1023px) {
    margin-top: 120px;
  }

  @media screen and (max-width: 567px) {
    margin-top: 100px;
  }
}

.subContent-selected-blog {

  font-style: normal;
  font-weight: normal;
  color: #000000;
  padding-left: 10px;
}

.SubContent2-selected-blog {

  font-style: normal;
  font-weight: normal;
  color: #000000;
  padding-left: 10px;
}

.body-selected-blog {

  font-style: normal;
  font-weight: normal;
  color: #000000;
  font-size: 16px;
  text-align: left;

  p {
    line-height: 134%;
  }

  a {
    color: #000000;
  }

  h2 {
    line-height: 134%;
  }

  h1 {
    line-height: 134%;
  }

  h3 {
    line-height: 134%;
  }

  ol li {
    line-height: 134%;
  }

  li {
    margin-bottom: 15px;
  }

  @media only screen and (min-width: 568px) and (max-width: 767px) {
    font-size: 14px;
  }

  @media only screen and (max-width: 567px) {
    font-size: 12px;
  }

  width: 100%;
}

.SubContent2Mob-selected-blog {

  font-style: normal;
  font-weight: normal;
  color: #000000;
  text-align: left;
  position: relative;
  padding-left: 10px;
  padding-bottom: 10px;
  line-height: 21px;
  font-size: 12px;
}

.SubContent2Desk-selected-blog {

  font-style: normal;
  font-weight: normal;
  color: #000000;
  text-align: left;
  position: relative;
  padding-left: 10px;
  padding-bottom: 10px;
  line-height: 21px;
  font-size: 16px;

  @media only screen and (max-width: 767px) {
    font-size: 14px;
  }
}

.SubContent2SpanBlog {
  font-size: 12px;
}

.SubContent2Span1-selected-blog {
  @media only screen and (min-width: 568px) and (max-width: 1023px) {
    font-size: 12px;
  }

  @media only screen and (max-width: 767px) {
    font-size: 144px;
  }
}

.MarginTopStory {
  @media only screen and (max-width: 567px) {
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 15px;
  }

  @media only screen and (min-width: 568px) {}
}

.PageContainer-selected-blog {
  z-index: 10;
  display: block;
  @media only screen and (max-width: 567px) {
    display: none;
  }
}

.PageContainer1-selected-blog {
  @media only screen and (max-width: 567px) {
    display: block;
  }

  @media only screen and (min-width: 568px) {
    display: none;
  }
}

.BlogPageSub2-related-blog {

  font-style: normal;
  font-weight: bold;
  color: #1c1e29;
  text-align: left;
  font-size: 20px;
  line-height: 26px;
  margin: 0;

  @media only screen and (min-width: 568px) and (max-width: 1024px) {
    font-size: 18px;
    line-height: 24px;
  }

  @media only screen and (max-width: 567px) {
    font-size: 18px;
    line-height: 24px;
  }
}

.SubContentPost-selected-blog {

  font-style: normal;
  font-weight: normal;
  color: #575757;
  text-align: left;
  position: relative;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 14px;
}

.Otherblogdiv-selected-blog {
  @media only screen and (min-width: 1024px) {
    margin-bottom: 40px;
  }

  @media only screen and (min-width: 568px) and (max-width: 1023px) {
    margin-bottom: 35px;
  }

  @media only screen and (max-width: 567px) {
    margin-bottom: 35px;
  }
}









.PageContainer-FifteenComponent {
  display: grid;
  grid-template-columns: auto auto auto;
  column-gap: 40px;
  row-gap: 40px;

  @media only screen and (max-width: 1023px) {
    column-gap: 25px;
    row-gap: 25px;
  }
}

.Greet-FifteenComponent {
  position: relative;
  background: #f8faff;
  box-shadow: 0px 0px 41.5338px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
}

.Greet2-FifteenComponent {
  position: absolute;
  bottom: 0%;
  padding: 40px;
}

.Para2-FifteenComponent {

  font-style: normal;
  font-weight: normal;
  color: #000000;
  text-align: left;
  font-size: 1em;
  line-height: 134%;
  margin: 0;
  padding-top: 10px;

  @media only screen and (max-width: 1024px) and (max-width: 1365px) {
    font-size: 14px;
    width: 120%;
  }

  @media only screen and (max-width: 1023px) {
    font-size: 12px;
  }
}

.PageContainer-footer {
  display: grid;
  grid-template-columns: 30% auto 30%;
  margin-top: 10px;
}

.ParaFooter {

  font-style: normal;
  font-weight: normal;
  color: #000000;
  text-align: left;
  font-size: 11px;
  line-height: 125%;
  margin: 0;
  position: relative;
  top: 12px;
  text-decoration: none;

  @media only screen and (max-width: 1023px) {
    font-size: 9px;
    top: 11px;
  }
}


.marginDiv1-buy-now {
  @media only screen and (min-width: 568px) {
    margin-left: 9vw;
    margin-right: 9vw;
  }
}

.PageContainer-buy-now {
  display: grid;
  z-index: 10;

  @media only screen and (min-width: 1366px) {
    grid-template-columns: 55% auto;
    column-gap: 80px;
  }

  @media only screen and (min-width: 1024px) and (max-width: 1365px) {
    grid-template-columns: 55% auto;
    column-gap: 40px;
  }

  @media only screen and (max-width: 1023px) {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 25px;
  }
}

.PageContainer1-buy-now {
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  justify-content: space-between;
  /* Horizontal */
}



.RingMargin {


  @media only screen and (min-width: 568px) {
    display: none;
  }

  @media only screen and (max-width: 567px) {
    margin-bottom: 40px;
    margin-top: 20px;
    display: block;
  }
}

.Para1NewBuyNow {

  font-style: normal;
  font-weight: 700;
  color: #000000;
  margin: 0;

  @media only screen and (min-width: 1366px) {
    font-size: 42px;
  }

  @media only screen and (min-width: 1024px) and (max-width: 1365px) {
    font-size: 32px;
  }

  @media only screen and (min-width: 568px) and (max-width: 1023px) {
    font-size: 32px;
  }

  @media only screen and (max-width: 567px) {
    font-size: 24px;
  }
}

.Para2NewBuyNow {

  font-style: normal;
  font-weight: 700;
  color: #000000;
  margin: 0;

  @media only screen and (min-width: 1366px) {
    font-size: 28px;
  }

  @media only screen and (min-width: 1024px) and (max-width: 1365px) {
    font-size: 24px;
  }

  @media only screen and (min-width: 568px) and (max-width: 1023px) {
    font-size: 22px;
  }

  @media only screen and (max-width: 567px) {
    font-size: 18px;
  }
}


.RingTitleBuy {

  font-style: normal;
  font-weight: normal;
  color: #666666;
  margin: 0;
  line-height: 143.19%;

  @media only screen and (min-width: 1024px) {
    font-size: 14px;
  }

  @media only screen and (min-width: 568px) and (max-width: 1023px) {
    font-size: 14px;
  }

  @media only screen and (max-width: 567px) {
    font-size: 12px;
  }
}

.StarDiv {

  font-style: normal;
  font-weight: normal;
  color: #666666;
  margin: 0;
  line-height: 143.19%;
  font-size: 10px;
}

.ButtonMarginDivBuy {
  @media only screen and (min-width: 1024px) {
    margin-top: 30px;
  }

  @media only screen and (min-width: 568px) and (max-width: 1023px) {
    margin-top: 30px;
  }

  @media only screen and (max-width: 567px) {
    margin-top: 40px;
  }
}

.ButtonMarginDivCoupon {
  @media only screen and (min-width: 1024px) {
    margin-top: 30px;
  }

  @media only screen and (min-width: 568px) and (max-width: 1023px) {
    margin-top: 30px;
  }

  @media only screen and (max-width: 567px) {
    margin-top: 40px;
  }
}

.DhyanaTextBuy {

  font-style: normal;
  font-weight: normal;
  color: #666666;
  margin: 0;
  line-height: 143.19%;

  @media only screen and (min-width: 1024px) {
    margin-bottom: 30px;
    font-size: 14px;
  }

  @media only screen and (min-width: 568px) and (max-width: 1023px) {
    margin-bottom: 30px;
    font-size: 14px;
  }

  @media only screen and (max-width: 567px) {
    margin-bottom: 40px;
    font-size: 12px;
  }
}

.SupCurrency {

  font-style: normal;
  font-weight: normal;
  color: #000000;
  margin: 0;
  line-height: 143.19%;
  position: relative;
  top: 3px;

  @media only screen and (min-width: 1024px) {
    font-size: 14px;
  }

  @media only screen and (min-width: 568px) and (max-width: 1023px) {
    font-size: 14px;
  }

  @media only screen and (max-width: 567px) {
    font-size: 14px;
  }
}


// .glossary-card-grid {
//   margin-top: 30px;
//   margin-bottom: 60px;
//   display: grid;
//   grid-template-columns: repeat(3, 1fr);

//   @media only screen and (max-width: 567px) {
//     margin-top: 20px;
//     margin-bottom: 50px;
//     grid-template-columns: repeat(1, 1fr);
//     row-gap: 20px;
//   }

//   row-gap: 20px;
//   column-gap: 20px;
// }

// .alphabet-list-div {
//   @media only screen and (min-width: 1024px) {
//     margin-top: 60px;
//     margin-bottom: 60px;
//   }

//   @media only screen and (min-width: 568px) and (max-width: 1023px) {
//     margin-top: 50px;
//     margin-bottom: 50px;
//   }

//   @media only screen and (max-width: 567px) {
//     margin-top: 50px;
//     margin-bottom: 50px;
//   }
// }

.glossary-card {
  background: rgba(255, 255, 255, 1);
  padding: 50px 40px 50px 40px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  cursor: pointer;
}

.glossary-card:hover {
  opacity: 1;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  transition: opacity 0.3s ease-in-out;
}

// .glossary-continue {
//   font-style: normal;
//   font-weight: 600;
//   color: #000000;
//   margin: 0;
//   font-size: 12px;
//   margin-top: 35px;
//   text-transform: uppercase;
//   text-align: right;
// }

// .letterSign {
//   font-style: normal;
//   font-weight: 700;
//   color: #000000;
//   margin: 0;

//   @media only screen and (min-width: 1024px) {
//     font-size: 42px;
//     line-height: 51px;
//   }

//   @media only screen and (min-width: 568px) and (max-width: 1023px) {
//     font-size: 34px;
//     line-height: 43px;
//   }

//   @media only screen and (max-width: 567px) {
//     font-size: 26px;
//     line-height: 32px;
//   }
// }

// .glossary-topic {
//   font-style: normal;
//   font-weight: 700;
//   color: #000000;
//   margin: 0;

//   @media only screen and (min-width: 1024px) {
//     font-size: 33px;
//     line-height: 40px;
//     margin-bottom: 30px;
//   }

//   @media only screen and (min-width: 568px) and (max-width: 1023px) {
//     font-size: 28px;
//     line-height: 30px;
//     margin-bottom: 20px;
//   }

//   @media only screen and (max-width: 567px) {
//     font-size: 24px;
//     line-height: 23.62px;
//     margin-bottom: 20px;
//   }
// }

// .glossary-para {
//   font-style: normal;
//   font-weight: 400;
//   color: #000000;
//   margin: 0;

//   @media only screen and (min-width: 1024px) {
//     font-size: 16px;
//     line-height: 23px;
//   }

//   @media only screen and (min-width: 568px) and (max-width: 1023px) {
//     font-size: 14px;
//     line-height: 21px;
//   }

//   @media only screen and (max-width: 567px) {
//     font-size: 14px;
//     line-height: 21px;
//   }
// }

// .glossary-term {
//   font-style: normal;
//   font-weight: 600;
//   color: rgba(243, 149, 38, 1);
//   margin: 0;

//   @media only screen and (min-width: 1024px) {
//     font-size: 18px;
//     line-height: 23px;
//     padding-bottom: 30px;
//   }

//   @media only screen and (min-width: 568px) and (max-width: 1023px) {
//     font-size: 16px;
//     line-height: 21px;
//     padding-bottom: 20px;
//   }

//   @media only screen and (max-width: 567px) {
//     font-size: 16px;
//     line-height: 21px;
//     padding-bottom: 20px;
//   }
// }

// .glossary-definition {
  // font-style: normal;
  // font-weight: 400;
  // color: #000000;
  // margin: 0;

  // @media only screen and (min-width: 1024px) {
  //   font-size: 16px;
  //   line-height: 25.6px;
  // }

  // @media only screen and (min-width: 568px) and (max-width: 1023px) {
  //   font-size: 14px;
  //   line-height: 19.2px;
  // }

  // @media only screen and (max-width: 567px) {
  //   font-size: 14px;
  //   line-height: 19.2px;
  // }
// }

// .alphabet-line {
//   font-style: normal;
//   font-weight: 600;
//   color: rgba(243, 149, 38, 1);
//   margin: 0;

//   a {
//     margin-right: 15px;
//     margin-bottom: 15px;
//     color: rgba(243, 149, 38, 1);
//     text-decoration: none;
//   }

//   :hover {
//     text-decoration: underline;
//   }

//   @media only screen and (min-width: 1024px) {
//     font-size: 32px;
//     line-height: 40px;
//   }

//   @media only screen and (min-width: 568px) and (max-width: 1023px) {
//     font-size: 26px;
//     line-height: 30px;
//   }

//   @media only screen and (max-width: 567px) {
//     font-size: 26px;
//     line-height: 50px;
//     text-align: left;
//   }

//   word-wrap: break-word;
// }

// #divIDGlossary {
//   font-style: normal;
//   font-weight: normal;
//   color: #000000;
//   text-align: left;
//   font-size: 1em;
//   line-height: 134%;
//   margin: 0;
//   margin-top: 40px;

//   @media only screen and (max-width: 1024px) and (max-width: 1365px) {
//     font-size: 14px;
//   }

//   @media only screen and (max-width: 1023px) {
//     font-size: 12px;
//     margin-top: 30px;
//   }
// }